var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.disp)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-12 px-3",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('div',[(
            _vm.received_order.shipment.length == 0 &&
            _vm.received_order.order_status !=
              _vm.$receivedOrderStatus.cancel_received_order
          )?_c('v-btn',{staticClass:"mr-2 text-right",attrs:{"rounded":"","outlined":"","to":("/admin/received_order/" + _vm.received_order_id + "/edit"),"justify":"right"}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("mdi-pencil")]),_c('span',[_vm._v("受注編集")])],1):_vm._e(),(
            (_vm.received_order.order_status ==
              _vm.$receivedOrderStatus.received_ordered ||
              _vm.received_order.order_status ==
                _vm.$receivedOrderStatus.fixed_delivery_date ||
              _vm.received_order.order_status ==
                _vm.$receivedOrderStatus.estimated_shipment ||
              _vm.received_order.order_status ==
                _vm.$receivedOrderStatus.division_storing) &&
            _vm.remaining_shipments.reduce(function (sum, element) { return sum + element; }, 0) !=
              0
          )?_c('v-btn',{staticClass:"mr-2 text-right",attrs:{"rounded":"","outlined":"","to":("/admin/received_order/" + _vm.received_order_id + "/shipment/create"),"justify":"right"}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("mdi-package-variant-plus")]),_c('span',[_vm._v("ロット引当")])],1):_vm._e(),(
            (_vm.received_order.order_status ==
              _vm.$receivedOrderStatus.received_ordered ||
              _vm.received_order.order_status ==
                _vm.$receivedOrderStatus.fixed_delivery_date ||
              _vm.received_order.order_status ==
                _vm.$receivedOrderStatus.estimated_shipment ||
              _vm.received_order.order_status ==
                _vm.$receivedOrderStatus.division_storing) &&
            _vm.remaining_shipments.reduce(function (sum, element) { return sum + element; }, 0) !=
              0
          )?_c('v-btn',{staticClass:"text-right",attrs:{"rounded":"","outlined":"","to":("/admin/received_order/" + _vm.received_order_id + "/pre_shipment/create"),"justify":"right"}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("mdi-truck-fast")]),_c('span',[_vm._v("事前出荷登録")])],1):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"title_box px-1",attrs:{"cols":"12","md":"1"}},[_vm._v("顧客注文番号")]),_c('v-col',{staticClass:"box",attrs:{"cols":"12","md":"2"}},[_vm._v(_vm._s(_vm.received_order.order_no))]),_c('v-col',{staticClass:"title_box",attrs:{"cols":"12","md":"1"}},[_vm._v("受注日")]),_c('v-col',{staticClass:"box",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.dateFormatJP(_vm.received_order.order_date))+" ")]),_c('v-col',{staticClass:"title_box",attrs:{"cols":"12","md":"1"}},[_vm._v("顧客名")]),_c('v-col',{staticClass:"box",attrs:{"cols":"12","md":"2"}},[_vm._v(_vm._s(_vm.customer.name))]),(this.received_order.branch_id)?_c('v-col',{staticClass:"title_box",attrs:{"cols":"12","md":"1"}},[_vm._v("支店")]):_vm._e(),(this.received_order.branch_id)?_c('v-col',{staticClass:"box",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.branch.name)+" ")]):_vm._e()],1),_c('v-row',{staticClass:"pt-1"},[_c('v-col',{staticClass:"title_box",attrs:{"cols":"12","md":"1"}},[_vm._v("受注状況")]),_c('v-col',{staticClass:"box",attrs:{"cols":"12","md":"2"}},[(
              _vm.received_order.order_status ==
              _vm.$receivedOrderStatus.new_received_order
            )?_c('div',{staticClass:"no_shipped_status"},[_vm._v(" 新規受注 ")]):_vm._e(),(
              _vm.received_order.order_status ==
              _vm.$receivedOrderStatus.received_ordered
            )?_c('div',{staticClass:"no_shipped_status"},[_vm._v(" 受注済み ")]):_vm._e(),(
              _vm.received_order.order_status ==
              _vm.$receivedOrderStatus.fixed_delivery_date
            )?_c('div',{staticClass:"no_shipped_status"},[_vm._v(" 納期回答済 ")]):_vm._e(),(
              _vm.received_order.order_status ==
              _vm.$receivedOrderStatus.estimated_shipment
            )?_c('div',{staticClass:"no_shipped_status"},[_vm._v(" 出荷予定 ")]):_vm._e(),(_vm.received_order.order_status == _vm.$receivedOrderStatus.shipped)?_c('div',[_vm._v(" 出荷済み ")]):_vm._e(),(
              _vm.received_order.order_status ==
              _vm.$receivedOrderStatus.cancel_received_order
            )?_c('div',{staticClass:"no_shipped_status"},[_vm._v(" 受注キャンセル ")]):_vm._e(),(
              _vm.received_order.order_status ==
              _vm.$receivedOrderStatus.division_storing
            )?_c('div',{staticClass:"no_shipped_status"},[_vm._v(" 分納 ")]):_vm._e()]),_c('v-col',{staticClass:"title_box",attrs:{"cols":"12","md":"1"}},[_vm._v("希望納品日")]),_c('v-col',{staticClass:"box justify-space-between",attrs:{"cols":"12","md":"2"}},[(_vm.received_order.is_asap == 1)?_c('div',[_vm._v("即日")]):_vm._e(),(!_vm.received_order.is_asap == 1)?_c('div',[_vm._v(" "+_vm._s(_vm.dateFormatJP(_vm.received_order.desired_delivery_date) || "ー")+" ")]):_vm._e(),_c('v-btn',{attrs:{"text":"","icon":"","rounded":""},on:{"click":function($event){(_vm.delivery_date_dialog = true),
                (_vm.is_update_fixed_delivery_date = false)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-col',{staticClass:"title_box",attrs:{"cols":"12","md":"1"}},[_vm._v("回答納品日")]),_c('v-col',{staticClass:"box justify-space-between",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.dateFormatJP(_vm.received_order.fixed_delivery_date) || "ー")+" "),_c('v-btn',{attrs:{"text":"","icon":"","rounded":""},on:{"click":function($event){(_vm.delivery_date_dialog = true),
                (_vm.is_update_fixed_delivery_date = true)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1),_c('v-row',{staticClass:"pt-1"},[_c('v-col',{staticClass:"title_box",attrs:{"cols":"12","md":"1"}},[_vm._v("納品先")]),_c('v-col',{staticClass:"box",attrs:{"cols":"12","md":"4"}},[_vm._v(" "+_vm._s(_vm.received_order.destination || "ー")+" ")]),_c('v-col',{staticClass:"title_box",attrs:{"cols":"12","md":"1"}},[_vm._v("登録者")]),_c('v-col',{staticClass:"box",attrs:{"cols":"12","md":"1"}},[_vm._v(_vm._s(_vm.received_order.user_name))])],1),_c('v-dialog',{attrs:{"persistent":"","width":"360px","max-width":"80%"},model:{value:(_vm.delivery_date_dialog),callback:function ($$v) {_vm.delivery_date_dialog=$$v},expression:"delivery_date_dialog"}},[_c('v-card',[(!_vm.is_update_fixed_delivery_date)?_c('v-card-title',{staticClass:"headline justify-center pb-0"},[_vm._v("希望納品日修正")]):_vm._e(),(_vm.is_update_fixed_delivery_date)?_c('v-card-title',{staticClass:"headline justify-center pb-0"},[_vm._v("回答納品日修正")]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[(!_vm.is_update_fixed_delivery_date)?_c('v-menu',{ref:"update_desired_delivery_date",attrs:{"close-on-content-click":false,"return-value":_vm.update_desired_delivery_date,"max-width":"290px","min-width":"290px","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){_vm.update_desired_delivery_date=$event},"update:return-value":function($event){_vm.update_desired_delivery_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"px-4",attrs:{"clearable":"","label":"希望納品日(任意)","readonly":"","disabled":!!_vm.update_is_asap},model:{value:(_vm.update_desired_delivery_date),callback:function ($$v) {_vm.update_desired_delivery_date=$$v},expression:"update_desired_delivery_date"}},'v-text-field',attrs,false),on))]}}],null,false,1238932478),model:{value:(_vm.menu.update_desired_delivery_date),callback:function ($$v) {_vm.$set(_vm.menu, "update_desired_delivery_date", $$v)},expression:"menu.update_desired_delivery_date"}},[_c('v-date-picker',{attrs:{"show-current":true,"day-format":function (date) { return new Date(date).getDate(); },"today":"","no-title":"","scrollable":""},on:{"change":function($event){return _vm.$refs.update_desired_delivery_date.save(
                      _vm.update_desired_delivery_date
                    )}},model:{value:(_vm.update_desired_delivery_date),callback:function ($$v) {_vm.update_desired_delivery_date=$$v},expression:"update_desired_delivery_date"}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}},[(!_vm.is_update_fixed_delivery_date)?_c('v-checkbox',{attrs:{"label":"即日希望","inset":"","color":"secondary"},on:{"change":function($event){_vm.update_desired_delivery_date = ''}},model:{value:(_vm.update_is_asap),callback:function ($$v) {_vm.update_is_asap=$$v},expression:"update_is_asap"}}):_vm._e()],1)],1),(_vm.is_update_fixed_delivery_date)?_c('v-menu',{ref:"update_fixed_delivery_date",attrs:{"close-on-content-click":false,"return-value":_vm.update_fixed_delivery_date,"max-width":"290px","min-width":"290px","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){_vm.update_fixed_delivery_date=$event},"update:return-value":function($event){_vm.update_fixed_delivery_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"px-4",attrs:{"clearable":"","label":"回答納品日(任意)","readonly":""},model:{value:(_vm.update_fixed_delivery_date),callback:function ($$v) {_vm.update_fixed_delivery_date=$$v},expression:"update_fixed_delivery_date"}},'v-text-field',attrs,false),on))]}}],null,false,2941187731),model:{value:(_vm.menu.update_fixed_delivery_date),callback:function ($$v) {_vm.$set(_vm.menu, "update_fixed_delivery_date", $$v)},expression:"menu.update_fixed_delivery_date"}},[_c('v-date-picker',{attrs:{"show-current":true,"day-format":function (date) { return new Date(date).getDate(); },"today":"","no-title":"","scrollable":""},on:{"change":function($event){return _vm.$refs.update_fixed_delivery_date.save(
                  _vm.update_fixed_delivery_date
                )}},model:{value:(_vm.update_fixed_delivery_date),callback:function ($$v) {_vm.update_fixed_delivery_date=$$v},expression:"update_fixed_delivery_date"}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-space-around pb-4"},[_c('v-btn',{attrs:{"color":"lighten-4","depressed":""},on:{"click":function($event){return _vm.closeDesiredDeliveryDateDialog()}}},[_vm._v("キャンセル")]),_c('v-btn',{staticClass:"secondary font-weight-bold",attrs:{"depressed":""},on:{"click":function($event){return _vm.desiredDeliveryDateSubmit(_vm.is_update_fixed_delivery_date)}}},[_vm._v("確定")])],1)],1)],1),_c('h3',{staticClass:"mt-12"},[_vm._v("受注内容")]),_c('v-data-table',{attrs:{"headers":_vm.product_headers,"items":_vm.setValue,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.division_storing",fn:function(ref){
                var item = ref.item;
return [(item.division_storing)?_c('div',{staticClass:"no_shipped_status"},[_vm._v(" "+_vm._s("分納")+" ")]):_vm._e()]}},{key:"item.product_code",fn:function(ref){
                var item = ref.item;
return [(item.product_code)?_c('div',[_vm._v(" "+_vm._s(item.product_code)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.customer_pn",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.customer_pn || "ー")+" ")])]}},{key:"item.orders_memo",fn:function(ref){
                var item = ref.item;
return [(item.orders_memo)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"rgba(0, 0, 0, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"blue--text"},'div',attrs,false),on),[_vm._v("●")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.orders_memo))])])],1):_vm._e(),(!item.orders_memo)?_c('div',[_vm._v(" "+_vm._s("ー")+" ")]):_vm._e()]}},{key:"item.memo",fn:function(ref){
                var item = ref.item;
return [(item.memo)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"rgba(0, 0, 0, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"blue--text"},'div',attrs,false),on),[_vm._v("●")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.memo))])])],1):_vm._e(),(!item.memo)?_c('div',[_vm._v(" "+_vm._s("ー")+" ")]):_vm._e()]}},{key:"item.quantity",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.quantity).toLocaleString())+" ")])]}},{key:"item.remaining_shipment",fn:function(ref){
                var item = ref.item;
return [_c('div',{class:{ no_shipped_status: item.division_storing }},[_vm._v(" "+_vm._s(Number(item.remaining_shipment).toLocaleString())+" ")])]}},{key:"item.selling_unit_price",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.selling_unit_price).toLocaleString())+" ")])]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.amount).toLocaleString())+" ")])]}}],null,true)}),_c('v-data-table',{attrs:{"width":"200px","headers":_vm.price_tag_headers,"items":_vm.received_order.price_tag_received_order,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.customer_pn",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.customer_pn || "ー")+" ")])]}},{key:"item.price_tag_no",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.price_tag_no || "ー")+" ")])]}},{key:"item.price",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s("￥" + Number(item.price).toLocaleString())+" ")]}},{key:"body.append",fn:function(ref){
                var headers = ref.headers;
return [_c('tr',{staticClass:"sum text-right"},[_c('td',{attrs:{"colspan":headers.length - 2}}),_c('td',[_vm._v("合計金額")]),_c('td',[_vm._v(" "+_vm._s("￥" + Number(_vm.received_order.amount).toLocaleString())+" ")])])]}}],null,true)}),_c('h3',{staticClass:"mt-12"},[_vm._v("出荷記録")]),(_vm.received_order.shipment.length > 0)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.shipment_headers,"items":_vm.received_order.shipment,"items-per-page":-1,"hide-default-footer":"","sort-by":"id","sort-desc":true},scopedSlots:_vm._u([{key:"item.shipment_date",fn:function(ref){
                var item = ref.item;
return [(item.shipment_status == _vm.$shipmentStatus.pre_shipment)?_c('div',{staticClass:"green--text text--darken-4"},[_vm._v(" "+_vm._s(item.shipment_date)+" ")]):_vm._e(),(
                item.shipment_status == _vm.$shipmentStatus.estimated_shipment
              )?_c('div',{staticClass:"orange--text text--darken-4"},[_vm._v(" "+_vm._s(item.shipment_date)+" ")]):_vm._e(),(item.shipment_status == _vm.$shipmentStatus.shipped)?_c('div',[_vm._v(" "+_vm._s(item.shipment_date)+" ")]):_vm._e()]}},{key:"item.shipment_status",fn:function(ref){
              var item = ref.item;
return [(item.shipment_status == _vm.$shipmentStatus.pre_shipment)?_c('div',{staticClass:"green--text text--darken-4"},[_vm._v(" 事前出荷 ")]):_vm._e(),(
                item.shipment_status == _vm.$shipmentStatus.estimated_shipment
              )?_c('div',{staticClass:"orange--text text--darken-4"},[_vm._v(" 出荷予定 ")]):_vm._e(),(item.shipment_status == _vm.$shipmentStatus.shipped)?_c('div',[_vm._v(" 出荷済み ")]):_vm._e()]}},{key:"item.product_name",fn:function(ref){
              var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[_c('div',[_vm._v(" "+_vm._s(product.product_name)+" ")])])})}},{key:"item.lot_no",fn:function(ref){
              var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[(product.lot_no)?_c('div',[_vm._v(" "+_vm._s(product.lot_no)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])])})}},{key:"item.shipped_quantity",fn:function(ref){
              var item = ref.item;
return _vm._l((item.product_shipment),function(product,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(Number(product.quantity).toLocaleString()))])])})}},{key:"item.user_name",fn:function(ref){
              var item = ref.item;
return [(item.user_id)?_c('div',[_vm._v(" "+_vm._s(_vm.userList.find(function (user) { return user.value == item.user_id; }).text)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.authorizer_name",fn:function(ref){
              var item = ref.item;
return [(item.authorizer)?_c('div',[_vm._v(" "+_vm._s(_vm.userList.find(function (user) { return user.value == item.authorizer; }).text)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.shipment",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","rounded":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.shipment_detail(item.id)}}},[_c('v-icon',[_vm._v("mdi-note")])],1)]}}],null,true)})],1):_c('div',[_vm._v("出荷記録はありません")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }